import React from 'react';
import styled, { keyframes } from 'styled-components';

const spinner = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const LoadingWrapper = styled.div`
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999999999;
  justify-content: center;
`;

const Spinner = styled.div`
  animation: ${spinner} 0.5s linear infinite;
  border-color: #000 rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  display: inline-block;
  height: 44px;
  width: 44px;
`;

export const LoaderFallBack = () => ({
  fallback: <AppLoading loading={true} />,
});

export default function AppLoading({ loading }) {
  return (
    <LoadingWrapper>
      <Spinner />
    </LoadingWrapper>
  );
}
