/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { FunctionComponent } from 'react';
import * as Router from 'router/lazyRouting';

export interface AppRoute {
  path: string;
  name: string;
  Component: FunctionComponent;
  category: string;
  title: string;
  icon: string;
  type?: string;
  permission?: RoutePermissions;
  role?: RouteRoles;
  categoryOrder?: number;
  categoryIcon?: CategoryIcon;
  isStatic: boolean;
  displayOrder: number;
}

export enum CategoryIcon {
  CARD = 'donut_small',
  ORDER_NUMBER = 'mic',
  USER_MANAGER = 'donut_small',
  PATIENT = 'accessibility',
  SETTING = 'settings_applications',
  REPORT = 'pie_charts',
  SWITCH_BOARD = 'record_voice_over',
  ROOM = 'room',
  ONLINE = 'apps',
  CHANEL = 'contact_phone',
  DASHBOARD = 'dashboard',
  MANAGEMENT = 'dehaze',
}

export enum RouteRoles {
  TEKMEDI = 'TEKMEDI',
  TNB = 'TNB',
  IT = 'IT',
  SURVEY = 'SURVEY',
  ADMIN = 'ADMIN',
  ROOM = 'ROOM',
  RO = 'RO',
  MANAGE = 'MANAGE',
}

export enum RoutePermissions {
  DASHBOARD = 'DASHBOARD.INDEX',
  USER_MANAGER = 'USER_MANAGER.INDEX',
  ROLE = 'ROLE.INDEX',
  SWITCH_BOARD = 'SWITCH_BOARD.INDEX',
  ROOM_ONLINE = 'ROOM_ONLINE.INDEX',
  CHECKIN = 'CHECKIN.INDEX',
  SWITCH_BOARD_REGISTER = 'SWITCH_BOARD_REGISTER.INDEX',
  MANAGEMENT = 'MANAGEMENT.INDEX',
}

export enum RouteCategory {
  DASHBOARD = 'Dashboard',
  ROOM = 'Phòng khám',
  ROOM_NUMBER = 'Số thứ tự online',
  CHECKIN = 'Số Thứ Tự Online',
  SWITCH_BOARD = 'Tổng đài',
  SWITCH_BOARD_REGISTER = 'Đăng ký số thứ tự',
  HUMAN_RESOURCE = 'Nhân sự',
  SETTING = 'Cài đặt',
  MANAGEMENT = 'Quản lý',
}

export const AppRouting: AppRoute[] = [
  // {
  //   path: '/dashboard',
  //   name: 'Dashboard Page',
  //   Component: Router.DashboardPage,
  //   category: RouteCategory.DASHBOARD,
  //   title: 'Dashboard',
  //   permission: RoutePermissions.DASHBOARD,
  //   role: RouteRoles.ADMIN,
  //   categoryIcon: CategoryIcon.DASHBOARD,
  //   categoryOrder: 1,
  //   isStatic: false,
  //   displayOrder: 1,
  //   icon: 'dashboard',
  // },
  // {
  //   path: '/detailed-report',
  //   name: 'Detailed Report Page',
  //   Component: Router.DetailedReportPage,
  //   category: RouteCategory.DASHBOARD,
  //   title: 'Báo cáo chi tiết',
  //   permission: RoutePermissions.DASHBOARD,
  //   role: RouteRoles.ADMIN,
  //   categoryIcon: CategoryIcon.DASHBOARD,
  //   categoryOrder: 1,
  //   isStatic: false,
  //   displayOrder: 2,
  //   icon: 'assessment',
  // },
  {
    path: '/switchboard',
    name: 'Manage STT Switch Board Page',
    Component: Router.SwitchBoard,
    category: RouteCategory.SWITCH_BOARD,
    title: 'Quản lý Tổng đài',
    permission: RoutePermissions.SWITCH_BOARD,
    role: RouteRoles.ADMIN,
    categoryIcon: CategoryIcon.SWITCH_BOARD,
    categoryOrder: 1,
    isStatic: false,
    displayOrder: 1,
    icon: 'record_voice_over',
  },
  {
    path: '/room-online',
    name: 'Setting Room',
    Component: Router.SettingRoom,
    category: RouteCategory.ROOM,
    title: 'Cấu hình phòng khám',
    permission: RoutePermissions.ROOM_ONLINE,
    role: RouteRoles.ROOM,
    categoryIcon: CategoryIcon.ROOM,
    categoryOrder: 2,
    icon: CategoryIcon.ROOM,
    isStatic: false,
    displayOrder: 1,
  },

  // {
  //   path: '/doctor',
  //   name: 'Doctor page',
  //   Component: Router.DoctorPage,
  //   category: RouteCategory.MANAGEMENT,
  //   title: 'Quản lý bác sĩ',
  //   permission: RoutePermissions.MANAGEMENT,
  //   role: RouteRoles.ADMIN,
  //   categoryIcon: CategoryIcon.MANAGEMENT,
  //   categoryOrder: 2,
  //   isStatic: false,
  //   displayOrder: 1,
  //   icon: 'supervisor_account',
  // },

  {
    path: '/online-number',
    name: 'Manage STT Page',
    Component: Router.OnlineNumber,
    category: RouteCategory.ROOM_NUMBER,
    title: RouteCategory.ROOM_NUMBER,
    permission: RoutePermissions.CHECKIN,
    role: RouteRoles.ADMIN,
    categoryOrder: 3,
    categoryIcon: CategoryIcon.ONLINE,
    icon: 'apps',
    isStatic: false,
    displayOrder: 1,
  },
  {
    path: '/hrm',
    name: 'HRM Page',
    Component: Router.UserManagement,
    category: RouteCategory.HUMAN_RESOURCE,
    title: 'Quản lý nhân sự',
    permission: RoutePermissions.USER_MANAGER,
    role: RouteRoles.ADMIN,
    categoryOrder: 4,
    categoryIcon: CategoryIcon.USER_MANAGER,
    icon: CategoryIcon.USER_MANAGER,
    isStatic: false,
    displayOrder: 1,
  },
  {
    path: '/role',
    name: 'System Access Page',
    Component: Router.Role,
    category: RouteCategory.SETTING,
    title: 'Quản lý quyền truy cập',
    permission: RoutePermissions.ROLE,
    role: RouteRoles.ADMIN,
    categoryOrder: 4,
    categoryIcon: CategoryIcon.SETTING,
    icon: 'lock',
    isStatic: false,
    displayOrder: 2,
  },
  {
    path: '/role-detail',
    name: 'Role Detail Page',
    Component: Router.RoleDetail,
    category: '',
    title: 'Thông tin quyền truy cập',
    icon: '',
    isStatic: true,
    displayOrder: 1,
  },
  {
    path: '/system-user',
    name: 'User Profile Page',
    Component: Router.UserPage,
    category: '',
    title: 'Thông tin tài khoản',
    icon: 'settings_system_daydream',
    isStatic: true,
    displayOrder: 1,
  },
];
