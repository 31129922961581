/* eslint-disable @typescript-eslint/no-unused-vars */
import { call, put, takeLatest, all } from 'redux-saga/effects';
import { actions } from './slice';
import { CommonHttp } from '../services/common.http';
import { HttpResponse } from '../services/response.http';
import { get } from 'lodash';
const commonRequest = new CommonHttp();
const interceptHttp = new HttpResponse(commonRequest);

export function* getDefaultSetting(api) {
  const response: any = yield call(api.getDefaultSetting);
  try {
    const data: any = yield interceptHttp.getGeneralApiResponse(response);
    yield put(actions.getDefaultSettingSuccess(data));
  } catch (error) {
    yield put(actions.getDefaultSettingFail(get(error, 'message')));
  }
}

export function* createSettingSetting(api, action) {
  const response: any = yield call(api.createSettingSetting, action.payload);
  try {
    const data: any = yield interceptHttp.getGeneralApiResponse(response);
    yield put(actions.createSettingSettingSuccess(data));
  } catch (error) {
    yield put(actions.createSettingSettingFail(get(error, 'message')));
  }
}

export function* updateSetting(api, action) {
  const response: any = yield call(api.updateSetting, action.payload);
  try {
    const data: any = yield interceptHttp.getGeneralApiResponse(response);
    yield put(actions.updateSettingSuccess(data));
  } catch (error) {
    yield put(actions.updateSettingFail(get(error, 'message')));
  }
}

export function* getLocations(api) {
  const response = yield call(api.getLocations);
  try {
    const data: any = yield interceptHttp.getGeneralApiResponse(response);
    yield put(actions.getLocationsSuccess(data));
  } catch (error) {
    yield put(actions.getLocationsFail(get(error, 'message')));
  }
}

export function* CommonSaga() {
  yield all([
    yield takeLatest(
      actions.getDefaultSetting.type,
      getDefaultSetting,
      commonRequest,
    ),
    yield takeLatest(
      actions.createSettingSetting.type,
      createSettingSetting,
      commonRequest,
    ),
    yield takeLatest(actions.updateSetting.type, updateSetting, commonRequest),
    yield takeLatest(actions.getLocations.type, getLocations, commonRequest),
  ]);
}
