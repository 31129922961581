/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import * as CommonSelectors from 'store/common/shared/selectors';
import { useSelector } from 'react-redux';

export function Footer() {
  const defaultSetting: any = useSelector(CommonSelectors.selectDefaultSetting);
  return (
    <div className="page-footer">
      <div className="page-footer-inner">
        {new Date().getFullYear()} © {defaultSetting.viName} System Development
        By
        <a href="tekmedi.com" target="_top" className="makerCss">
          {' '}
          Tekmedi JSC
        </a>
      </div>
    </div>
  );
}
