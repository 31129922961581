import { HttpRequest, serializeQuery } from 'utils/request';
import { APP_CONSTANT, MOMENT_CALL_API } from 'store/common/constants';
import { CommonHttp } from 'store/common/services/common.http';
import { CheckInRoutes } from '../constants';
import moment from 'moment';

export interface Board {
  id?: string;
  name: string;
  code: string;
  isActive?: boolean;
}

export interface TimeSlotRequest {
  departmentIds: string[];
  totalNumber: number;
  timeSlots: any[];
}

export interface ExportFilter {
  length: number;
  actives: boolean[];
}
export class TimeSlotHttp {
  request: any;
  constructor(endPoint = APP_CONSTANT.API.ENDPOINT) {
    this.request = new HttpRequest(endPoint).request;
  }

  private formatDate(date: any): string {
    return moment(date).format(MOMENT_CALL_API);
  }

  getTimeSlotsSetting = (filter: string[]): Promise<any> => {
    const queryString = serializeQuery({
      ids: filter.map(time => time),
    });
    return this.request.get(
      `${CheckInRoutes.GET_TIMESLOTS_SETTING}?${queryString}`,
    );
  };

  getAllTimeSlots = (): Promise<any> => {
    return this.request.get(CheckInRoutes.GET_ALL_TIMESLOTS);
  };

  getTimeSlotExists = (filter: any): Promise<any> => {
    return this.request.post(CheckInRoutes.GET_TIMESLOTS_EXITS, {
      ...CommonHttp.convertRequest(filter),
      registered_date: this.formatDate(filter.registeredDate),
    });
  };
}
