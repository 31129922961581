/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  useInjectReducer,
  useInjectSaga,
} from 'store/core/@reduxjs/redux-injectors';
import { useDispatch } from 'react-redux';
import * as AuthSlice from 'store/auth/shared/slice';
import * as CommonSlice from 'store/common/shared/slice';
import * as roomOnlineSlice from 'store/roomOnline/shared/slice';
import * as roomConfigSlice from 'store/roomConfig/shared/slice';
import * as timeSlotSlice from 'store/timeslots/shared/slice';
import { CommonSaga } from 'store/common/shared/saga';
import { AuthSaga } from 'store/auth/shared/saga';
import { RoomConfigSaga } from 'store/roomConfig/shared/saga';
import { RoomOnlineSaga } from 'store/roomOnline/shared/saga';
import { TimeSlotsSaga } from 'store/timeslots/shared/saga';
import {
  RESPONSE_CONSTANT,
  RESPONSE_MESSAGE,
} from 'store/auth/constants/auth.constant';

/* Routing */
import { AppRouting } from 'router';
import { SnackbarProvider } from 'notistack';
import {
  LocalStorageService,
  LocalStorageKey,
} from 'services/localStorage.service';

/* Layout */
import { Header } from 'app/components/Header';
import { Breadcrumb } from 'app/components/Breadcrumb';
import { Footer } from 'app/components/Footer';
import * as _ from 'lodash';

/* Static Route */
import { LoginPage } from 'router/lazyRouting';

/* Animated */
import { AnimatedSwitch } from 'react-router-transition';
import { bounceTransition } from 'animation/AnimationSwitch';
import { AppHelper } from 'utils/app.helper';
import { Unsubscribe } from 'redux';
import { useSnackbar } from 'notistack';
import { NotifyService } from 'services/notify.service';
import RootStore from 'store/configureStore';

export function App() {
  const local = new LocalStorageService();
  useInjectReducer({ key: AuthSlice.sliceKey, reducer: AuthSlice.reducer });
  useInjectSaga({ key: AuthSlice.sliceKey, saga: AuthSaga });
  useInjectReducer({ key: CommonSlice.sliceKey, reducer: CommonSlice.reducer });
  useInjectSaga({ key: CommonSlice.sliceKey, saga: CommonSaga });
  useInjectReducer({
    key: roomOnlineSlice.sliceKey,
    reducer: roomOnlineSlice.reducer,
  });
  useInjectSaga({ key: roomOnlineSlice.sliceKey, saga: RoomOnlineSaga });
  useInjectReducer({
    key: roomConfigSlice.sliceKey,
    reducer: roomConfigSlice.reducer,
  });
  useInjectSaga({ key: roomConfigSlice.sliceKey, saga: RoomConfigSaga });
  useInjectReducer({
    key: timeSlotSlice.sliceKey,
    reducer: timeSlotSlice.reducer,
  });
  useInjectSaga({ key: timeSlotSlice.sliceKey, saga: TimeSlotsSaga });
  const dispatch = useDispatch();
  const history = useHistory();
  const activatedRoute = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const notifyController = new NotifyService(enqueueSnackbar);

  React.useEffect(() => {
    function validateToken() {
      const tokenExpired: any = local.getItem(LocalStorageKey.tokenExpired);
      if (_.isEmpty(tokenExpired)) {
        return history.push('/');
      }
      const isExpiredToken: boolean = local.expiredToken(
        JSON.parse(tokenExpired),
      );
      if (isExpiredToken) {
        history.push('/');
        LocalStorageService.clear();
        return dispatch(
          AuthSlice.actions.setError({
            id: AppHelper.generateUUID() + Date.now(),
            key: RESPONSE_CONSTANT.TOKEN_EXPIRED,
            message: RESPONSE_MESSAGE.TOKEN_EXPIRED,
          }),
        );
      }
      const userValue: any = local.getItem(LocalStorageKey.user);
      const userId: any = JSON.parse(userValue);
      if (!_.isEmpty(userId)) {
        dispatch(AuthSlice.actions.getUserByIdToken(userId));
      }
    }

    function notifyAndRedirect({ type, payload }: { [x: string]: any }) {
      switch (type) {
        case AuthSlice.actions.getUserByIdTokenFail.type:
          history.push('/');
          notifyController.setNotifyMessage(payload).setFailNotify();
          break;
        case AuthSlice.actions.getListPermissionFail.type:
        case AuthSlice.actions.getListRoleFail.type:
        case AuthSlice.actions.loginFail.type:
          notifyController.setNotifyMessage(payload).setFailNotify();
          dispatch(AuthSlice.actions.clearError());
          break;
        case AuthSlice.actions.loginSuccess.type:
          notifyController
            .setNotifyMessage(RESPONSE_MESSAGE.LOGIN_SUCCESS)
            .setSuccessNotify();
          const userRoutes: any[] = _.get(payload, 'userRoutes');
          local.setLocalUser(payload);
          history.push(_.head(userRoutes).path);
          break;
        case AuthSlice.actions.updateProfileUserSuccess.type:
          notifyController
            .setNotifyMessage(
              RESPONSE_MESSAGE.UPDATE_PROFILE_USER_SUCCESS +
                'Vui lòng đăng nhập lại.',
            )
            .setSuccessNotify();
          dispatch(AuthSlice.actions.logout());
          history.push('/');
          break;
        case AuthSlice.actions.getUserByIdTokenSucess.type:
          notifyController
            .setNotifyMessage(RESPONSE_MESSAGE.GET_USER_BY_ID_SUCCESS)
            .setSuccessNotify();
          const userByTokenRoutes: any[] = _.get(payload, 'userRoutes');
          const activeRoute = _.get(activatedRoute, 'pathname');
          const search = _.get(activatedRoute, 'search');
          const canActiveRoute = userByTokenRoutes
            .map(({ path }) => path)
            .some(route => {
              return route === activeRoute;
            });
          if (!canActiveRoute) {
            return history.push(_.head(userByTokenRoutes).path);
          }
          if (search === '') {
            return history.push(activeRoute);
          }
          return history.push(activeRoute + search);
      }
    }
    validateToken();
    dispatch(timeSlotSlice.actions.getAllTimeSlots());
    dispatch(roomOnlineSlice.actions.getAllRoomsNotSetting());
    dispatch(roomOnlineSlice.actions.getAllRoomsSetting());
    dispatch(roomConfigSlice.actions.getAllRoomConfigNumber());
    dispatch(roomConfigSlice.actions.getAllRoomConfigNoNumber());
    dispatch(AuthSlice.actions.getListPermission());
    dispatch(AuthSlice.actions.getListRole());
    const storeSub$: Unsubscribe = RootStore.subscribe(() => {
      const { type, payload } = RootStore.getState().lastAction;
      notifyAndRedirect({ type, payload });
    });
    return () => {
      storeSub$();
    };
  }, []);

  const renderAppRouting = () => {
    return AppRouting.map(({ path, name, Component, title, isStatic }) => (
      <Route
        exact
        path={path}
        key={name}
        render={props => {
          const crumbs = AppRouting.filter(({ path }) =>
            props.match.path.includes(path),
          ).map(({ path, ...rest }) => {
            return {
              path: Object.keys(props.match.params).length
                ? Object.keys(props.match.params).reduce(
                    (path, param) =>
                      path.replace(`:${param}`, props.match.params[param]),
                    path,
                  )
                : path,
              ...rest,
            };
          });
          return (
            <React.Fragment>
              <Helmet>
                <title>{title}</title>
                <meta name="description" content={`Tekmedi Admin - ${title}`} />
              </Helmet>
              {!isStatic && <Breadcrumb crumbs={crumbs} />}
              <Component />
            </React.Fragment>
          );
        }}
      />
    ));
  };

  return (
    <SnackbarProvider
      maxSnack={5}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transitionDuration={{ enter: 300, exit: 200 }}
      autoHideDuration={2000}
    >
      <Switch>
        <AnimatedSwitch
          atEnter={bounceTransition.atEnter}
          atLeave={bounceTransition.atLeave}
          atActive={bounceTransition.atActive}
          className="route-wrapper"
        >
          <Route exact path="/" component={LoginPage} />
          <React.Fragment>
            <div className="page-wrapper">
              <Header />
              <section className="content">
                <div className="page-container">
                  <div className="page-content-wrapper">
                    <div className="page-content">{renderAppRouting()}</div>
                  </div>
                </div>
                <Footer />
              </section>
            </div>
          </React.Fragment>
        </AnimatedSwitch>
      </Switch>
    </SnackbarProvider>
  );
}
