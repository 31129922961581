export const RoomConfigRoute = {
  GET_CONFIG: '/api/SwitchBoardDepartment/department-setting',
  SWITCHBOARD_DEPT: '/api/SwitchBoardDepartment',
};

export const RESPONSE_CONSTANT = {
  ADD_NUMBER_SUCCESS: 'ADD_NUMBER_SUCCESS',
  ADD_NUMBER_FAIL: 'ADD_NUMBER_FAIL',
  SET_NUMBER_ERROR: 'SET_NUMBER_ERROR',
};

export const RESPONSE_MESSAGE = {
  ADD_NUMBER_SUCCESS: 'Tạo số thứ tự cho phòng khám thành công',
  UPDATE_NUMBER_SUCCESS: 'Cập nhật số thứ tự cho phòng khám thành công',
  ADD_NUMBER_FAIL: '',
  SET_NUMBER_ERROR: 'Số thứ tự không được trống',
};
