import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import { RoomOnlineHttp } from 'store/roomOnline/services/roomOnline.http';
import { HttpResponse } from 'store/common/services/response.http';
import { actions } from './slice';
import { get } from 'lodash';

const http = new RoomOnlineHttp();
const interceptHttp = new HttpResponse(http);

export function* getAllRooms(api, action) {
  const response = yield call(api.getAllRooms, action.payload);
  try {
    const data: any = yield interceptHttp.getGeneralApiResponse(response);
    yield put(actions.getAllRoomsSuccess(data));
  } catch (error) {
    yield put(actions.getAllRoomsFail(get(error, 'message')));
  }
}

export function* getAllRoomsSetting(api) {
  const response = yield call(api.getAllRoomsSetting);
  try {
    const data: any = yield interceptHttp.getGeneralApiResponse(response);
    yield put(actions.getAllRoomsSettingSuccess(data));
  } catch (error) {
    yield put(actions.getAllRoomsSettingFail(get(error, 'message')));
  }
}

export function* getAllRoomsNotSetting(api) {
  const response = yield call(api.getAllRoomsNotSetting);
  try {
    const data: any = yield interceptHttp.getGeneralApiResponse(response);
    yield put(actions.getAllRoomsNotSettingSuccess(data));
  } catch (error) {
    yield put(actions.getAllRoomsNotSettingFail(get(error, 'message')));
  }
}

export function* updateRoomSetting(api, action) {
  const response = yield call(api.updateRoomSetting, action.payload);
  try {
    const data: any = yield interceptHttp.getGeneralApiResponse(response);
    yield put(actions.updateRoomSettingSuccess(data));
  } catch (error) {
    yield put(actions.updateRoomSettingFail(get(error, 'message')));
  }
}

export function* setRoomSetting(api, action) {
  const response = yield call(api.setRoomSetting, action.payload);
  try {
    const data: any = yield interceptHttp.getGeneralApiResponse(response);
    yield put(actions.setRoomSettingSuccess(data));
  } catch (error) {
    yield put(actions.setRoomSettingFail(get(error, 'message')));
  }
}

export function* RoomOnlineSaga() {
  yield all([
    yield takeLatest(actions.getAllRooms.type, getAllRooms, http),
    yield takeLatest(actions.getAllRoomsSetting.type, getAllRoomsSetting, http),
    yield takeLatest(actions.updateRoomSetting.type, updateRoomSetting, http),
    yield takeLatest(
      actions.getAllRoomsNotSetting.type,
      getAllRoomsNotSetting,
      http,
    ),
    yield takeLatest(actions.setRoomSetting.type, setRoomSetting, http),
  ]);
}
