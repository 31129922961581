import { HttpRequest } from 'utils/request';
import { APP_CONSTANT } from 'store/common/constants';
import { CommonHttp } from 'store/common/services/common.http';
import { RoomOnlineRoutes } from '../constants';
import { AppHelper } from 'utils/app.helper';

enum RoomType {
  ALL = 0,
  SETTING = 1,
  NOT_SETTING = 2,
}
export interface TimeSlotRequest {
  departmentIds: string[];
  totalNumber: number;
  timeSlots: any[];
}
export class RoomOnlineHttp {
  request: any;
  constructor(endPoint = APP_CONSTANT.API.ENDPOINT) {
    this.request = new HttpRequest(endPoint).request;
  }

  getAllRooms = (filter: any): Promise<any> => {
    return this.request.get(RoomOnlineRoutes.GET_ALL_ROOMS, {
      ...CommonHttp.convertRequest(filter),
      start: (filter.page - 1) * filter.pageSize,
      length: filter.pageSize,
      start_date: AppHelper.formatDateAPI(filter.startDate),
      end_date: AppHelper.formatDateAPI(filter.endDate),
    });
  };

  getAllRoomsSetting = (): Promise<any> => {
    return this.request.get(
      `${RoomOnlineRoutes.GET_ALL_ROOMS}/${RoomType.SETTING}`,
    );
  };

  getAllRoomsNotSetting = (): Promise<any> => {
    return this.request.get(
      `${RoomOnlineRoutes.GET_ALL_ROOMS}/${RoomType.NOT_SETTING}`,
    );
  };

  updateRoomSetting = ({ id, room }): Promise<any> => {
    return this.request.put(`${RoomOnlineRoutes.GET_ALL_ROOMS}/${id}`, {
      ...CommonHttp.convertRequest(room),
    });
  };

  deleteRoomSetting = (id: string): Promise<any> => {
    return this.request.delete(`${RoomOnlineRoutes.GET_ALL_ROOMS}/${id}`);
  };

  setRoomSetting = (setting: any): Promise<any> => {
    return this.request.post(RoomOnlineRoutes.GET_ALL_ROOMS, {
      ...CommonHttp.convertRequest(setting),
    });
  };
}
