import { createSlice } from '@reduxjs/toolkit';
import { flatten } from 'lodash';
import { AppHelper } from 'utils/app.helper';

export interface RoomConfigState {
  loading: boolean;
  error: any;
  success?: any;
  allRoomConfig: any[];
  roomConfigsNumber: any[];
  roomConfigsNoNumber: any[];
  roomConfig: any;
}

export const initialState: RoomConfigState = {
  loading: false,
  error: null,
  success: {},
  allRoomConfig: [],
  roomConfigsNumber: [],
  roomConfigsNoNumber: [],
  roomConfig: null,
};

const roomConfigSlice = createSlice({
  name: 'roomConfig',
  initialState,
  reducers: {
    getAllRoomConfig(state) {
      state.loading = true;
    },

    getAllRoomConfigSuccess(state, action) {
      state.loading = false;
      state.allRoomConfig = action.payload;
    },

    getAllRoomConfigFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    getRoomConfigById(state, action) {
      state.loading = true;
    },

    getRoomConfigByIdSuccess(state, action) {
      state.loading = false;
      state.roomConfig = action.payload;
    },

    getRoomConfigByIdFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    setRoomConfig(state, action) {
      state.loading = true;
    },

    setRoomConfigSuccess(state, action) {
      state.loading = false;
    },

    setRoomConfigFail(state, action) {
      state.loading = false;
    },

    updateRoomConfig(state, action) {
      state.loading = true;
    },

    updateRoomConfigSuccess(state, action) {
      state.loading = false;
    },

    updateRoomConfigFail(state, action) {
      state.loading = false;
    },

    getAllRoomConfigNumber(state) {
      state.loading = true;
    },

    getAllRoomConfigNumberSuccess(state, action) {
      state.loading = false;
      state.roomConfigsNumber = action.payload;
    },

    getAllRoomConfigNumberFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    getAllRoomConfigNoNumber(state) {
      state.loading = true;
    },

    getAllRoomConfigNoNumberSuccess(state, action) {
      state.loading = false;
      state.roomConfigsNoNumber = action.payload;
    },

    getAllRoomConfigNoNumberFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    setError(state, action) {
      state.error = {
        message: action.payload,
        id: AppHelper.generateUUID(),
      };
    },

    clearSuccess(state) {
      state.success = null;
    },

    clearError(state) {
      state.error = null;
    },
  },
});

export const { actions, reducer, name: sliceKey } = roomConfigSlice;

export function reducerHelper(listOrder: any[] = []): any[] {
  if (!listOrder.length) return [];
  return flatten(listOrder.map(({ data }) => data));
}
