import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import { RoomConfigHttp } from 'store/roomConfig/services/roomConfig.http';
import { HttpResponse } from 'store/common/services/response.http';
import { actions } from './slice';
import { get } from 'lodash';

const http = new RoomConfigHttp();
const interceptHttp = new HttpResponse(http);

export function* getAllRoomConfig(api) {
  const response = yield call(api.getAllRoomConfig);
  try {
    const data: any = yield interceptHttp.getGeneralApiResponse(response);
    yield put(actions.getAllRoomConfigSuccess(data));
  } catch (error) {
    yield put(actions.getAllRoomConfigFail(get(error, 'message')));
  }
}

export function* getRoomConfigById(api, action) {
  const response = yield call(api.getRoomConfigById, action.payload);
  try {
    const data: any = yield interceptHttp.getGeneralApiResponse(response);
    yield put(actions.getRoomConfigByIdSuccess(data));
  } catch (error) {
    yield put(actions.getRoomConfigByIdFail(get(error, 'message')));
  }
}

export function* getAllRoomConfigNumber(api) {
  const response = yield call(api.getAllRoomConfigNumber);
  try {
    const data: any = yield interceptHttp.getGeneralApiResponse(response);
    yield put(actions.getAllRoomConfigNumberSuccess(data));
  } catch (error) {
    yield put(actions.getAllRoomConfigNumberFail(get(error, 'message')));
  }
}

export function* getAllRoomConfigNoNumber(api) {
  const response = yield call(api.getAllRoomConfigNoNumber);
  try {
    const data: any = yield interceptHttp.getGeneralApiResponse(response);
    yield put(actions.getAllRoomConfigNoNumberSuccess(data));
  } catch (error) {
    yield put(actions.getAllRoomConfigNoNumberFail(get(error, 'message')));
  }
}

export function* setRoomConfig(api, action) {
  const response = yield call(api.setRoomConfig, action.payload);
  try {
    const data: any = yield interceptHttp.getGeneralApiResponse(response);
    yield put(actions.setRoomConfigSuccess(data));
  } catch (error) {
    yield put(actions.setRoomConfigFail(get(error, 'message')));
  }
}

export function* updateRoomConfig(api, action) {
  const response = yield call(api.updateRoomConfig, action.payload);
  try {
    const data: any = yield interceptHttp.getGeneralApiResponse(response);
    yield put(actions.updateRoomConfigSuccess(data));
  } catch (error) {
    yield put(actions.updateRoomConfigFail(get(error, 'message')));
  }
}

export function* RoomConfigSaga() {
  yield all([
    yield takeLatest(actions.setRoomConfig.type, setRoomConfig, http),
    yield takeLatest(actions.getRoomConfigById.type, getRoomConfigById, http),
    yield takeLatest(actions.updateRoomConfig.type, updateRoomConfig, http),
    yield takeLatest(actions.getAllRoomConfig.type, getAllRoomConfig, http),
    yield takeLatest(
      actions.getAllRoomConfigNumber.type,
      getAllRoomConfigNumber,
      http,
    ),
    yield takeLatest(
      actions.getAllRoomConfigNoNumber.type,
      getAllRoomConfigNoNumber,
      http,
    ),
  ]);
}
