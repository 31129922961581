/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useInjectReducer } from 'store/core/@reduxjs/redux-injectors';
import { NavbarItem } from './NavbarItem';
import { useSelector } from 'react-redux';
import * as AuthSlice from 'store/auth/shared/slice';
import * as AuthSelector from 'store/auth/shared/selectors';
import * as _ from 'lodash';

export function Navbar() {
  useInjectReducer({ key: AuthSlice.sliceKey, reducer: AuthSlice.reducer });
  const user = useSelector(AuthSelector.selectUser);

  const renderDataItem = () => {
    if (!_.isEmpty(user.userRoutes)) {
      const groupRoutes: any[] = _.chain(user.userRoutes)
        .groupBy('category')
        .map(childRoute => {
          return {
            title: _.first(childRoute).category,
            isStatic: _.first(childRoute).isStatic,
            childRoute,
          };
        })
        .value();
      if (groupRoutes.length) {
        return groupRoutes
          .filter(route => !route.isStatic)
          .map((item: any, index: number) => {
            return <NavbarItem route={item} key={index} />;
          });
      }
    }
  };

  return (
    <div className="navbar-custom">
      <div className="hor-menu hidden-sm hidden-xs">
        <ul className="nav navbar-nav">{renderDataItem()}</ul>
      </div>
    </div>
  );
}
