import { HttpRequest } from 'utils/request';
import { APP_CONSTANT } from 'store/common/constants';
import { RoomConfigRoute } from '../constants';

enum RoomConfigType {
  ALL = 0,
  NUMBER = 1,
  NO_NUMBER = 2,
}
export interface TimeSlotRequest {
  departmentIds: string[];
  totalNumber: number;
  timeSlots: any[];
}

export class RoomConfigHttp {
  request: any;
  constructor(endPoint = APP_CONSTANT.API.ENDPOINT) {
    this.request = new HttpRequest(endPoint).request;
  }

  private formatRoomConfig = (request: TimeSlotRequest): any => {
    return {
      department_Ids: request.departmentIds,
      total_number: request.totalNumber,
      time_slots: request.timeSlots.map((time: any) => ({
        time_start: time.timeStart,
        time_end: time.timeEnd,
        number: time.number,
      })),
    };
  };

  public getAllRoomConfig = (): Promise<any> => {
    return this.request.get(
      `${RoomConfigRoute.GET_CONFIG}/${RoomConfigType.ALL}`,
    );
  };

  public getAllRoomConfigNumber = (): Promise<any> => {
    return this.request.get(
      `${RoomConfigRoute.GET_CONFIG}/${RoomConfigType.NUMBER}`,
    );
  };

  public getAllRoomConfigNoNumber = (): Promise<any> => {
    return this.request.get(
      `${RoomConfigRoute.GET_CONFIG}/${RoomConfigType.NO_NUMBER}`,
    );
  };

  public getRoomConfigById = (id: string): Promise<any> => {
    return this.request.get(RoomConfigRoute.SWITCHBOARD_DEPT + '/' + id);
  };

  public setRoomConfig = (request: any): Promise<any> => {
    return this.request.post(
      RoomConfigRoute.SWITCHBOARD_DEPT,
      this.formatRoomConfig(request),
    );
  };

  public updateRoomConfig = (request: any): Promise<any> => {
    return this.request.put(
      RoomConfigRoute.SWITCHBOARD_DEPT,
      this.formatRoomConfig(request),
    );
  };
}
