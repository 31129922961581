/* eslint-disable array-callback-return */
import * as _ from 'lodash';

export class PrivateAuthRoute {
  user: any;
  constructor(user: any) {
    this.user = user;
  }

  public seperateRoute(defaultRoute: any[]): any[] {
    const permissions: any[] = _.get(this.user, 'permissions');
    const userRole: any = _.get(this.user, 'role.code');
    if (_.isEmpty(permissions) || _.isEmpty(userRole)) {
      throw new Error(
        'User chưa được cấp quyền truy cập vào website, vui lòng liên hệ admin.',
      );
    }
    if (userRole === 'ADMIN') {
      return defaultRoute;
    }
    const userRoute: any = _.flatten(
      permissions
        .map(permission => {
          const authRoute = defaultRoute.filter(
            route => route.permission === permission.code,
          );
          if (authRoute) {
            return authRoute;
          }
        })
        .filter(route => route),
    ).sort((cur, next) => cur.categoryOrder - next.categoryOrder);
    if (!userRoute.length) {
      throw new Error(
        'User chưa được cấp quyền truy cập vào website, vui lòng liên hệ admin.',
      );
    }
    return userRoute;
  }
}
