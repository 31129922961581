/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpRequest } from 'utils/request';
import { ApiResponse } from 'apisauce';
import { isEmpty, get } from 'lodash';

interface IResponse {
  data?: any;
  duration?: number;
  ok?: boolean;
  status: number;
}

export class HttpResponse<HttpRequest> {
  http: HttpRequest;
  constructor(http: HttpRequest) {
    this.http = http;
  }
  public getGeneralApiResponse(response: ApiResponse<IResponse>): any | void {
    if (!response.ok) {
      if (isEmpty(response.data)) {
        throw new Error(response.problem);
      }
      const message = get(response.data, 'message');
      if (message) {
        throw new Error(message);
      }
    }
    return get(response.data, 'result');
  }
}
