import { createSlice } from '@reduxjs/toolkit';

export interface TimeSlotState {
  loading: boolean;
  error: any;
  success?: any;
  timeSlots?: any;
  timeSlotsSetting?: any;
  timeSlotsExists?: any;
}

export const initialState: TimeSlotState = {
  loading: false,
  error: null,
  success: {},
  timeSlotsSetting: [],
  timeSlotsExists: null,
  timeSlots: [],
};

const timeSlotSlice = createSlice({
  name: 'timeslots',
  initialState,
  reducers: {
    getTimeSlotsSetting(state, action) {
      state.loading = true;
      state.error = null;
    },

    getTimeSlotsSettingSuccess(state, action) {
      state.loading = false;
      state.timeSlotsSetting = action.payload;
    },

    getTimeSlotsSettingFail(state, action) {
      state.loading = false;
      state.error = {
        message: action.payload,
      };
    },

    getTimeSlotExists(state, action) {
      state.loading = true;
      state.error = null;
    },

    getTimeSlotExistsSuccess(state, action) {
      state.loading = false;
      state.timeSlotsExists = action.payload;
    },

    getTimeSlotExistsFail(state, action) {
      state.loading = false;
      state.error = {
        message: action.payload,
      };
    },

    getAllTimeSlots(state) {
      state.loading = true;
      state.error = null;
    },

    getAllTimeSlotsSuccess(state, action) {
      state.loading = false;
      state.timeSlots = action.payload;
    },

    getAllTimeSlotsFail(state, action) {
      state.loading = false;
      state.error = {
        message: action.payload,
      };
    },

    clearTimeSlotSetting(state) {
      state.timeSlotsSetting = [];
    },

    clearSuccess(state) {
      state.success = null;
    },

    clearError(state) {
      state.error = null;
    },
  },
});

export const { actions, reducer, name: sliceKey } = timeSlotSlice;
